<template>
  <div>
    <div class="breadCurmb mt-3">
      <label>
        <router-link to="/">{{ $t("Home") }}</router-link>
      </label>
      <span>/</span>
      <label>{{ $t("WorkGroup") }}</label>
      <span>/</span>
      <label active>{{ $t("Drivers") }}</label>
    </div>

    <!-- Start Dialog Delete -->
    <v-dialog v-model="dialogDelete" :width="config.deleteModalWidth">
      <v-card>
        <v-toolbar color="third" dense flat>
          <v-toolbar-title class="text-body-2 font-weight-bold white-clr">
            {{ $t("Confirm") }}
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text class="py-0 pa-4 pt-4 black--text">
          <h3 class="clr-primary">
            {{ $t("AreYouSureYouWantToDelete") }}
          </h3>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="danger"
            text
            small
            class="body-2 font-weight-bold"
            @click.native="dialogDelete = false"
            >{{ $t("Cancel") }}</v-btn
          >
          <v-btn
            color="success"
            class="body-2 font-weight-bold"
            outlined
            small
            @click.native="deleteItemConfirm"
          >
            {{ $t("Ok") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- End Dialog Delete -->

    <v-toolbar flat>
      <v-toolbar-title>{{ $t(config.tableName) }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-dialog
        v-model="dialog"
        :width="config.modalFormWidth"
        @click:outside="closeModal"
      >
        <template v-slot:activator="{ on, attrs }">
          <div>

            <v-btn
              color="info"
              @click="clearData"
              class="mb-2"
              v-bind="attrs"
              v-on="on"
              small
            >
              <span class="addNewLabel">{{ $t("AddNew") }}</span>

              <v-icon class="my-0 mr-2 ml-2" dense>mdi-file</v-icon>
            </v-btn>

            <!-- <v-btn
              v-if="
                user &&
                user.permissions &&
                user.permissions.includes('store_customer')
                || true
              "
              color="info"
              @click="clearData"
              class="mb-2"
              v-bind="attrs"
              v-on="on"
              small
            >
              <span class="addNewLabel">{{ $t("AddNew") }}</span>

              <v-icon class="my-0 mr-2 ml-2" dense>mdi-file</v-icon>
            </v-btn> -->
            <!-- <download-excel
              class="
                mb-2
                mx-5
                cursorPointer
                v-btn
                theme--dark
                v-size--small
                success
                v-btn v-btn--is-elevated v-btn--has-bg
              "
              :data="table.items"
              :fields="table.itemExport"
              type="xls"
              :header="$t(config.tableName)"
              :worksheet="$t(config.tableName)"
              :name="$t(config.tableName)"
            >
              <span class="excelLabel">{{ $t("ExportExcel") }}</span>
              <v-icon
                class="my-0 mr-2 ml-2"
                style="color: #fff !important"
                dense
                >mdi-export</v-icon
              >
            </download-excel>

            <v-btn
              color="danger"
              @click="loadPDF"
              dark
              small
              class="mb-2 mr-2 ml-2"
            >
              <span class="pdfLabel"> {{ $t("LoadPDF") }} </span>
              <v-icon class="my-0 mr-2 ml-2" dense>mdi-eye</v-icon>
            </v-btn> -->
          </div>
        </template>

        <div v-if="dialog">
          <v-card>
            <v-card-title>
              <span class="text-h5" v-if="formData.id > 0">
                {{ $t("Edit") }}
              </span>
              <span class="text-h5" v-else> {{ $t("Add") }} </span>
            </v-card-title>

            <v-card-text>
              <v-container fluid>
                <v-row>
                  <v-col class="py-0" :cols="6">
                    <div
                      :class="{
                        'has-error': errors.has(`addEditValidation.name`),
                      }"
                    >
                      <label>{{ $t("DriverName") }}</label>
                      <v-text-field
                        class="d-block my-2"
                        type="text"
                        name="name"
                        data-vv-scope="addEditValidation"
                        v-validate="'required'"
                        :data-vv-as="$t('DriverName')"
                        hide-details
                        dense
                        outlined
                        v-model="formData.name"
                      ></v-text-field>
                    </div>

                    <div
                      class="help-block"
                      v-if="errors.has(`addEditValidation.name`)"
                    >
                      {{ errors.first(`addEditValidation.name`) }}
                    </div>
                  </v-col>

                      <v-col class="py-0" :cols="6">
                    <div
                      :class="{
                        'has-error': errors.has(`addEditValidation.mobile`),
                      }"
                    >
                      <label>{{ $t("Mobile") }}</label>
                      <v-text-field
                        class="d-block my-2"
                        type="text"
                        name="mobile"
                        data-vv-scope="addEditValidation"
                        v-validate="'required'"
                        :data-vv-as="$t('Mobile')"
                        hide-details
                        dense
                        outlined
                        v-model="formData.mobile"
                      ></v-text-field>
                    </div>

                    <div
                      class="help-block"
                      v-if="errors.has(`addEditValidation.mobile`)"
                    >
                      {{ errors.first(`addEditValidation.mobile`) }}
                    </div>
                  </v-col>

                    <v-col class="py-0" :cols="6">
                    <div
                      :class="{
                        'has-error': errors.has(`addEditValidation.email`),
                      }"
                    >
                      <label>{{ $t("Email") }}</label>
                      <v-text-field
                        class="d-block my-2"
                        type="text"
                        name="email"
                        data-vv-scope="addEditValidation"
                        v-validate="'required'"
                        :data-vv-as="$t('Email')"
                        hide-details
                        dense
                        outlined
                        v-model="formData.email"
                      ></v-text-field>
                    </div>

                    <div
                      class="help-block"
                      v-if="errors.has(`addEditValidation.email`)"
                    >
                      {{ errors.first(`addEditValidation.email`) }}
                    </div>
                  </v-col>

                    <v-col class="py-0" :cols="6" v-if="!is_edit">
                    <div
                      :class="{
                        'has-error': errors.has(`addEditValidation.password`),
                      }"
                    >
                      <label>{{ $t("Password") }}</label>
                      <v-text-field
                        class="d-block my-2"
                        type="password"
                        name="password"
                        data-vv-scope="addEditValidation"
                        v-validate="'required'"
                        :data-vv-as="$t('Password')"
                        hide-details
                        dense
                        outlined
                        v-model="formData.password"
                      ></v-text-field>
                    </div>

                    <div
                      class="help-block"
                      v-if="errors.has(`addEditValidation.password`)"
                    >
                      {{ errors.first(`addEditValidation.password`) }}
                    </div>
                  </v-col>

                   <v-col class="py-0" :cols="6" v-if="!is_edit">
                    <div
                      :class="{
                        'has-error': errors.has(`addEditValidation.confirm_password`),
                      }"
                    >
                      <label>{{ $t("ConfirmPassword") }}</label>
                      <v-text-field
                        class="d-block my-2"
                        type="password"
                        name="confirm_password"
                        data-vv-scope="addEditValidation"
                        v-validate="'required'"
                        :data-vv-as="$t('ConfirmPassword')"
                        hide-details
                        dense
                        outlined
                        v-model="formData.confirm_password"
                      ></v-text-field>
                    </div>

                    <div
                      class="help-block"
                      v-if="errors.has(`addEditValidation.confirm_password`)"
                    >
                      {{ errors.first(`addEditValidation.confirm_password`) }}
                    </div>
                  </v-col>
                    <v-col class="py-0" :cols="6" v-if="!is_edit">
                    <div
                      :class="{
                        'has-error': errors.has(`addEditValidation.city_id`),
                      }"
                    >
                      <label>{{ $t("City") }}</label>
                       <v-autocomplete
                        clearable
                          class="d-block my-2"
                          name="city_id"
                          data-vv-scope="addEditValidation"
                          v-validate="'required'"
                          :data-vv-as="$t('City')"
                          hide-details
                          dense
                          outlined
                          v-model.number="formData.city_id"
                          item-value="city_id"
                          item-text="city_name"
                          :items="cityList"
                        ></v-autocomplete>

                    </div>

                    <div
                      class="help-block"
                      v-if="errors.has(`addEditValidation.city_id`)"
                    >
                      {{ errors.first(`addEditValidation.city_id`) }}
                    </div>
                  </v-col>

                  

                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-container fluid>
                <v-row class="float-left">
                  <v-btn
                    class="mr-2 ml-2"
                    color="danger"
                    dark
                    text
                    small
                    @click="closeModal"
                  >
                    {{ $t("Cancel") }}
                  </v-btn>
                  <v-btn
                    class="mr-2 ml-5"
                    color="success"
                    dark
                    outlined
                    small
                    @click="save"
                  >
                    {{ $t("Save") }}
                  </v-btn>
                </v-row>
              </v-container>
            </v-card-actions>
          </v-card>
        </div>
      </v-dialog>
    </v-toolbar>

    <!-- Start Filters -->
    <div>
      <v-row>
        <v-col :md="3">
          <div>
            <label>{{ $t("Name") }}</label>
            <v-text-field
              class="d-block my-2"
              type="text"
              name="name"
              hide-details
              dense
              outlined
              v-model="filters.name"
            ></v-text-field>
          </div>
        </v-col>

         <v-col :md="3">
          <div>
            <label>{{ $t("Mobile") }}</label>
            <v-text-field
              class="d-block my-2"
              type="text"
              name="mobile"
              hide-details
              dense
              outlined
              v-model="filters.mobile"
            ></v-text-field>
          </div>
        </v-col>

          <v-col :md="3">
          <div>
            <label>{{ $t("Email") }}</label>
            <v-text-field
              class="d-block my-2"
              type="text"
              name="mobile"
              hide-details
              dense
              outlined
              v-model="filters.email"
            ></v-text-field>
          </div>
        </v-col>

        <!-- <v-col :md="3">
          <div>
            <label>{{ $t("Status") }}</label>
            <v-select
              :clearable="false"
              class="d-block my-2"
              name="status"
              hide-details
              dense
              outlined
              :multiple="false"
              item-text="text"
              item-value="value"
              v-model="filters.status"
              :items="filtersItems"
            ></v-select>
          </div>
        </v-col> -->

        <v-col :md="1" class="mt-6">
          <v-btn
            color="third"
            hide-details
            @click="getDataFromApi"
            style="display: block; margin-top: 10px"
            class="w-full h-btn white-clr"
            small
          >
            {{ $t("Filter") }}
          </v-btn>
        </v-col>
        <v-col :md="1" class="mt-6">
          <v-btn
            color="danger"
            hide-details
            @click="clearFilters"
            style="display: block; margin-top: 10px"
            class="w-full h-btn white-clr"
            small
          >
            {{ $t("Clear") }}
          </v-btn>
        </v-col>
      </v-row>
    </div>
    <!-- End Filters -->

    <!-- Start DataTable -->
    <v-data-table
      :headers="table.headers"
      :items="table.items"
      :loading="table.loading"
      :items-per-page="table.itemsPerPage"
      :page.sync="table.page"
      hide-default-footer
      class="elevation-1"
      :loading-text="$t('LoadData')"
    >
      <template v-slot:no-data>
        <v-alert class="mt-5 white-clr" color="third">
          {{ $t("NoData") }}
        </v-alert>
      </template>

      <template v-slot:item.status="{ item }">
        <p
          @click="updateStatus(item)"
          v-if="item.status"
          class="my-0 clr-success clickable"
        >
          {{ $t("Active") }}
        </p>
        <p @click="updateStatus(item)" v-else class="my-0 clr-danger clickable">
          {{ $t("InActive") }}
        </p>
      </template>

      <template v-slot:item.actions="{ item }">
        
         <v-icon
          class="my-0 mr-2 ml-2"
          dense
          @click="goDetails(item.id)"
          style="color: purple !important; cursor: pointer; font-size: 22px"
          >mdi-eye</v-icon
        >

        
        <v-icon
          v-if="
            user &&
            user.permissions &&
            user.permissions.includes('update_customer')
            || true
          "
          class="my-0 mr-2 ml-2"
          dense
          @click="
           editData(item)
          "
          color="success"
          >mdi-pencil</v-icon
        >



        <!-- <v-icon
          v-if="
            user &&
            user.permissions &&
            user.permissions.includes('delete_customer')
          "
          class="my-0 mr-2 ml-2"
          dense
          @click="
            formData = Object.assign({}, item);
            dialogDelete = true;
          "
          color="danger"
          >mdi-delete</v-icon> -->
      </template>
    </v-data-table>
    <!-- End DataTable -->

    <!-- Start Pagination -->
    <div class="text-center pt-2">
      <v-pagination
        v-if="table.length"
        total-visible="5"
        v-model="table.page"
        :length="table.length"
        @input="changePage(table.page)"
      ></v-pagination>
    </div>

    <!-- End Pagination -->
  </div>
</template>

<script>
import moment from "moment";
export default {
  name: "customers",
  data() {
    return {
      is_edit: false,
      cityList: [],
      user: localStorage.getItem("user")
        ? JSON.parse(localStorage.getItem("user"))
        : [],
      filtersItems: [
        { text: this.$t("All"), value: null },
        { text: this.$t("Active"), value: 1 },
        { text: this.$t("InActive"), value: 0 },
      ],
      config: {
        tableName: "DriversTable",
        crudHeader: "Drivers",
        crudApi: "driver",
        filterWidth: "20rem",
        deleteModalWidth: "30rem",
        modalFormWidth: "50rem",
      },
      table: {
        page: 1,
        length: null,
        itemsPerPage: 10,
        loading: false,
        items: [],
        itemExport: {
          "#": "id",
          "الاسم عربي": {
            field: "name.ar",
            callback: (item) => {
              if (item) return item;
            },
          },
          "الاسم إنجليزي": {
            field: "name.en",
            callback: (item) => {
              if (item) return item;
            },
          },
          الحالة: {
            field: "status",
            callback: (value) => {
              if (value) return "فعال";
              else return "غير فعال";
            },
          },
        },
        headers: [
          { text: "#", value: "id", width: "5rem" },
          {
            text: this.$t("DriverName"),
            value: "name",
            width: "10rem",
          },
          {
            text: this.$t("Mobile"),
            value: "mobile",
            width: "10rem",
          },
          {
            text: this.$t("Email"),
            value: "email",
            width: "10rem",
          },
          {
            text: this.$t("City"),
            value: "city_name",
            width: "10rem",
          },
        ],
      },
      filters: {
        name: null,
        mobile: null,
        email: null,
        // status: null,
      },
      formData: {
        id: 0,
        name: null,
        mobile: null,
        email: null,
        password:null,
        confrim_password:null,
        city_id: null,
      },
      drawer: false,
      dialog: false,
      dialogDelete: false,
    };
  },
  methods: {
    editData(item) {
      this.formData = Object.assign({}, item);
      this.formData.city_id= parseInt(item.city_id);
      this.dialog = true;
      this.is_edit= true;
    },
    getCities() {
      this.$store.dispatch(`driver/listCities`).then((res) => {
        this.cityList = res.data;
      });
    },
    goDetails(id) {
       this.$router.push({ name: 'driverDetails', params: { id: id } })
    },
    loadPDF() {
      localStorage.setItem("filterReport", JSON.stringify(this.filters));
      window.open(`/reports/${this.config.crudApi}`);
    },
    clearFilters() {
      for (let key in this.filters) {
        this.filters[key] = null;
      }
      this.getDataFromApi();
    },
    clearData() {
      this.is_edit= false;
      this.formData = {
        name: null,
        mobile: null,
        email: null,
        password:null,
        confrim_password:null,
        city_id: null,
      };
      this.$validator.reset();
    },
    deleteItemConfirm() {
      this.deleteData(
        `${this.config.crudApi}/removeData`,
        this.formData.id
      ).then(() => {
        this.getDataFromApi();
        this.dialogDelete = false;
      });
    },
    updateStatus(item) {
      item.isLoading = true;
      this.updateStatusData(`${this.config.crudApi}/updateStatus`, item.id)
        .then(() => {
          this.getDataFromApi();
        })
        .finally(() => {
          item.isLoading = false;
        });
    },
    closeModal() {
      this.formData.id = 0;
      this.dialog = false;
      this.$validator.reset();
    },
    save() {
      this.$validator.validateAll("addEditValidation").then((valid) => {
        if (!valid) return this.notifyCustomError("Error", "ValidationsErrors");
        this.saveUpdateData(
          `${this.config.crudApi}/saveData`,
          `${this.config.crudApi}/updateData`,
          this.formData
        ).then(() => {
          this.getDataFromApi();
          this.closeModal();
        });
      });
    },
    getDataFromApi(options) {
      this.table.loading = true;

      let sendData = {...this.filters,...options};
      this.$store.dispatch(`driver/getData`,sendData)
        .then((res) => {
          this.table.items = res.data.resources;
          this.table.length = Math.ceil(res.data.pagination.total / res.data.pagination.per_page);
        })
        .finally(() => {
          this.table.loading = false;
        });
    },
    changePage(page) {
      let options = {
        page: page,
      };
      this.getDataFromApi(options);
    },
  },
  mounted() {
    this.getDataFromApi();

    this.getCities();

    // if (
    //   (this.user &&
    //     this.user.permissions &&
    //     this.user.permissions.includes("update_customer")) ||
    //   (this.user &&
    //     this.user.permissions &&
    //     this.user.permissions.includes("delete_customer"))
    // )
      this.table.headers.push({
        text: this.$t("Actions"),
        value: "actions",
        sortable: false,
        align: "center",
        width: "8rem",
      });
  },
};
</script>